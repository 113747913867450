<template>
   <div>
    <v-banner
        single-line
        height="500"
        class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/banner-Numberc.png"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="px-6 max-width-1200 height-per-100 d-flex align-center">
          <v-card color="transparent" flat class="flex-grow-1">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  数字资产
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px">
                  帮助企业更高效的评估数字资产，有助于企业顺利转型与升级
                </v-card-title>
              </div>
            </div>
          </v-card>
          <!-- <div class="flex-grow-1">
            <v-img src="https://h5.ophyer.cn/official_website/banner/banner-fte-img.png" width="90%"></v-img>
          </div> -->
        </v-container>
      </v-img>
    </v-banner>

    <div class="con con1" style="background: #fff;">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>数字确权</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <v-card class="wrap part-one d-flex justify-center" flat style="background: #fff;">
          <div class="pr-10 borderimg" >
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img01.png"/>
          </div>
          <div class="text-wrap" style="padding-right:160px">
            <v-card class="pa-0" flat style="background: #fff;">
              <v-card-title  class="con_tit pa-0 mb-5">提供可信的存证服务</v-card-title>
              <v-card-text  class="con_text pa-0">将身份、信息、资产、行为上链，无法修改</v-card-text>
              <v-card-text class="text">• 数据采用分布式账本保存，并由各有公信力的权威节点共同见证</v-card-text>
              <v-card-text class="text">• 高数据安全、高性能低延时以及集群灾备高等特点保证了数据的可靠流转</v-card-text>
              <v-card-text class="text">• 业界领先的电子合同、金融、版权、租赁等应用场景和商业实践</v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

     <div class="con con2" style="background: #FBFBFB;">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>解决方案</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <v-card class="wrap part-one d-flex justify-center" flat style="background: #FBFBFB;">
          <div class="pr-10 borderimg" >
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img02.png"/>
          </div>
          <div class="text-wrap" style="padding-right:160px">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">海量3D模型授权</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #fff;padding-top: 63px;">
      <v-card flat class="max-width-1200" color="transparent">
        <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat style="background: #fff;">
          <div class="pl-10 borderimg_two">
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img03.png" />
          </div>
          <div class="text-wrap" style="padding-left: 200px">
            <v-card class="pa-0"  flat>
              <v-card-title class="con_tit pa-0 mb-5" >数字资产交易</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #FBFBFB;padding-top: 63px;">
      <v-card flat class="max-width-1200" color="transparent">
        <v-card class="wrap part-one d-flex justify-center" flat style="background: #FBFBFB;">
          <div class="pr-10 borderimg" >
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img04.png"/>
          </div>
          <div class="text-wrap" style="padding-right:160px">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">数字资产价格标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #fff;padding-top: 63px;">
      <v-card flat class="max-width-1200" color="transparent">
        <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat style="background: #fff;">
          <div class="pl-10 borderimg_two">
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img05.png" />
          </div>
          <div class="text-wrap" style="padding-left: 200px">
            <v-card class="pa-0"  flat>
              <v-card-title class="con_tit pa-0 mb-5" >数字资产指数标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #FBFBFB;padding-top: 63px;">
      <v-card flat class="max-width-1200" color="transparent">
        <v-card class="wrap part-one d-flex justify-center" flat style="background: #FBFBFB;">
          <div class="pr-10 borderimg" >
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img06.png"/>
          </div>
          <div class="text-wrap" style="padding-right:160px">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">区块链身份验证标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #fff;padding-top: 63px;">
      <v-card flat class="max-width-1200" color="transparent">
        <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat style="background: #fff;">
          <div class="pl-10 borderimg_two">
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img07.png" />
          </div>
          <div class="text-wrap" style="padding-left: 200px">
            <v-card class="pa-0"  flat>
              <v-card-title class="con_tit pa-0 mb-5" >区块链防伪溯源标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con2" style="background: #FBFBFB;padding-top: 63px;">
      <v-card flat class="max-width-1200" color="transparent">
        <v-card class="wrap part-one d-flex justify-center" flat style="background: #FBFBFB;">
          <div class="pr-10 borderimg" >
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img08.png"/>
          </div>
          <div class="text-wrap" style="padding-right:160px">
            <v-card class="pa-0" flat style="background: #FBFBFB;">
              <v-card-title  class="con_tit pa-0 mb-5">图计算反洗钱标准</v-card-title>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con3" style="background: #fff;">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>应用领域</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <ul>
          <li>
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img09.png"/>
            <p>固定资产上链</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img10.png"/>
            <p>区块链产品溯源</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img11.png"/>
            <p>通证资产管理</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/other/Numberc-img12.png"/>
            <p>区块链股权</p>
          </li>
        </ul>
      </v-card>
    </div>

    <div class="con con4" style="background: #FBFBFB;">
      <v-card flat class="max-width-1200" color="transparent" style="margin: 0 auto;">
        <div class="ftyd-public-title">
          <div>
            <span>精选案例</span>
            <span>3D Content productio</span>
          </div>
        </div>
      </v-card>

       <v-container style="max-width:1200px;">
          <ul>
            <li>
              <img src="https://h5.ophyer.cn/official_website/other/Numberc-img13.png"/>
              <p>区块链登记溯源系统</p>
            </li>
            <li>
              <img src="https://h5.ophyer.cn/official_website/other/Numberc-img14.png"/>
              <p>KYC服务</p>
            </li>
            <li>
              <img src="https://h5.ophyer.cn/official_website/other/Numberc-img15.png"/>
              <p>数字货币审计标准</p>
            </li>
          </ul>
      </v-container>
    </div>
   </div>
</template>

<script>

export default {
  name: "OnlineRetailers",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      list: [
        {
          title: 'V-View',
          tip: 'FT 3D engine',
          text: '为室内场景提供三维空间重建能力',
          bg: '#FFFFFF',
          type: 'left',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Online1.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '展览展示'
              }
            ]
          }
        },
        {
          title: 'AR-Panel',
          tip: 'FT 3D engine',
          text: '智能触控技术为核心的智能交互桌面式显示终端',
          bg: '#FBFBFB',
          type: 'right',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Online1.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: 'V-LED',
          tip: 'FT 3D engine',
          text: '在小间距LED的基础上增加交互装置 打造了替代投影教学方案的全新沉浸式教学系统',
          bg: '#fff',
          type: 'left',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Online1.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: 'V-CAVE',
          tip: 'FT 3D engine',
          text: 'V-CAVE投影系统是由3个面以上硬质背投影墙组成是一套专门为虚拟仿真实验而打造的沉浸系统',
          bg: '#FBFBFB',
          type: 'right',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Online1.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: 'VST',
          tip: 'FT 3D engine',
          text: '半实物虚拟仿真培训平台 是一套结合了算法、数据、工艺操作的大型综合模拟操作培训系统',
          bg: '#fff',
          type: 'left',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Online1.png',
            ul: [
            
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: 'V-Station',
          tip: 'FT 3D engine',
          text: '基于全息台用户可以在自由的空间内对模型进行多角度观察，并进行分析、修改、操控等交互',
          bg: '#FBFBFB',
          type: 'right',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Online1.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: 'V-Motion',
          tip: 'FT 3D engine',
          text: 'VR安全教育流动站 构筑具有强烈真实感和沉浸感的各种应急安全事件场景 让体验者自由参与到每个教育环节中',
          bg: '#fff',
          type: 'left',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Online1.png',
            ul: [
            
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '消防应急'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '禁毒宣传'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '安全教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '行业体验'
              }
            ]
          }
        },
        {
          title: '全息沙盘',
          tip: 'FT 3D engine',
          text: '采用全息投影、多人互动传感等技术，在沙盘上呈现TB级别高精度三维模型。用户可以对沙盘上的模型进行编辑设计、改变颜色、移动缩放等操作',
          bg: '#FBFBFB',
          type: 'right',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Online1.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: '手势交互产品',
          tip: 'FT 3D engine',
          text: '通过监测人体手臂表皮的肌电神经信号，通过AI智能算法分析，反推手部动作，实现动作意图的解析。并将识别的动作作为人机交互的指令传递到VR的应用场景中，让体验更沉浸',
          bg: '#fff',
          type: 'left',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Online1.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
                tit: '军工策略'
              }
            ]
          }
        },
      ]
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
  
  }
}
</script>

<style scoped lang='scss'>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.banner_title{
  font-size: 60px;
  color: #FFFFFF !important;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}

.con {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
}
.con1 {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  background: #FBFBFB;
  .v-card {
    background: #FBFBFB;
  }
  .borderimg{
    width:600px;
    // height:300px;
    img{
      border-radius: 10px;
      float: right;
    }
  }
  .part-one{
    margin-bottom: 21px;
    .text-wrap{
      margin-top: -20px;
      width: 600px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top: 54px;
        position: relative;
        &::before {
          content: '';
          width: 60px;
          height: 4px;
          background: #000000;
          position: absolute;
          bottom: -10px;
          left: 0;
        }
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 26px;
      }
      .text {
        line-height: 0;
        margin: 0;
        padding-left: 0;
      }
    }
  }
}

.con2 {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  background: #FBFBFB;
  .v-card {
    background: #FBFBFB;
  }
  .borderimg{
    width:600px;
    img{
      border-radius: 10px;
      float: right;
    }
  }
.borderimg_two{
    width: 600px;
    // height: 300px;

    img{
      border-radius: 10px;
      float: left;
    }
  }
  .part-one{
    margin-bottom: 21px;
    .text-wrap{
      width: 600px;
      display: flex;
      align-items: center;
      padding-left: 100px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        position: relative;
        &::before {
          content: '';
          width: 60px;
          height: 4px;
          background: #000000;
          position: absolute;
          bottom: -10px;
          left: 0;
        }
      }
    }
  }
}

.con3 {
  display: block;
  padding-top: 1px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ul {
    display: flex;
  }
  li {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 20px;
    img {
      width: 260px;
      height: 146px;
      border-radius: 10px;
    }
    p {
      margin-top: 8px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
  }
}

.con4 {
  display: block;
  padding-top: 1px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  padding-left: 0;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li {
    margin: 0 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
  p {
    margin-top: 10px;
    text-align: center;
  }
}
</style>
